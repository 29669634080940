<template src="./navRecursion.html"></template>

<script>
import $ from 'jquery'
import CmsStore from '../CmsStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';

export default {
  name: "CmsNavRecursion",
  props: ['navItems'],
  mixins: [RegisterStoreModule],
  created: function(){
    this.registerStoreModule('cms', CmsStore);
  },
  computed: {
    ...mapFields('cms', [
      'template',
      'current_nav',
      'current_nav.id',
    ]),
  },
  methods: {
    toggleDropdown: function(event){
      $(event.target).parent().siblings().children('ul').removeClass("show");
      $(event.target).siblings().toggleClass("show");
      $(event.target).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', /* istanbul ignore next */ function(e) {
        $('.dropdown-submenu .show').removeClass("show");
      });
    },
    hideDropdown: function(){
      $('.navbar-nav').find('.show').removeClass("show");
    },
    navLinkClick: function(nav){
      this.$router.replace('/website/cms/' + this.$route.params.template + '/' + this.$route.params.language + '/' + nav.id).catch(err => {});
    },
    transformNavItems: function(navigation){
      var nav = [];
      nav.push({
        text: 'Hauptnavigation',
        value: 0
      });
      nav = this.navRecursion(nav, navigation, 0);
      return nav;
    },
    navRecursion: function(nav, nav_items, level, filter_parent_id = false, disabled = false){
      let self = this;
      nav_items.forEach(function (nav_item) {
        if(
          (
            (filter_parent_id === false && nav_item.owned) ||
            parseInt(filter_parent_id) === nav_item.parent_id
          )
        ) {
          nav.push({
            text: (!filter_parent_id) ? '- - '.repeat(level) + nav_item.title : nav_item.title,
            value: nav_item.id,
            disabled: (self.current_nav.id === nav_item.id || disabled || level >= 2),
            owned: nav_item.owned
          });
        }

        if(nav_item.children.length > 0){
          nav = self.navRecursion(nav, nav_item.children, (level + 1), filter_parent_id, (self.current_nav.id === nav_item.id || disabled));
        }
      });
      return nav;
    },
    navRecursionSidebar: function(nav_items, level){
      let self = this;
      let nav = [];
      let preventDoublePush = [];
      nav_items.forEach(function (nav_item) {
        nav.push({
          _name: (typeof(nav_item.children) !== 'undefined') && nav_item.children.length > 0 ? 'CSidebarNavDropdown' : 'CSidebarNavItem',
          name: (level > 1 ? '- ' : '') + nav_item.title,
          navID: nav_item.id,
          fontIcon: (level === 0 ? 'fa fa-circle-o' : ''),
          to: '/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/' + nav_item.id,
          _children: (typeof(nav_item.children) !== 'undefined') && nav_item.children.length > 0 ? self.navRecursionSidebar(nav_item.children, (level + 1)) : null,
          _nativeOn: {
            click: function (e) {
              if($(e.target).hasClass('c-sidebar-nav-dropdown-toggle') && nav_item.title.trim() === $(e.target).text().trim()){
                if(!preventDoublePush[nav_item.id] && $(e.target).parent('li').hasClass('c-show')) {
                  self.$router.push('/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/' + nav_item.id).catch(err => {});
                  preventDoublePush[nav_item.id] = true;
                }
                else if(preventDoublePush[nav_item.id] && !$(e.target).parent('li').hasClass('c-show')) {
                  preventDoublePush[nav_item.id] = false;
                }
              }
            },
          }
        });
      });

      if(nav_items[0].owned || (level === 0 && !self.hasOwnNav(nav_items))) {
        nav.push({
          _name: 'CSidebarNavItem',
          name: 'Neuer Menüpunkt',
          fontIcon: 'fa fa-plus',
          to: '/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/new/' + nav_items[0].id
        });
      }

      return nav;
    },
    addContent: function(parent_id){
      this.$router.replace('/website/cms/' + this.$route.params.template + '/' + this.$route.params.language + '/new/' + parent_id).catch(err => {});
    },
    hasOwnNav: function(nav_items){
      let user = this.$store.getters.getUser;
      if(user !== null && user.user_group === 1){
        return false;
      }

      let owned = 0;
      nav_items.forEach(function (nav_item) {
        if(nav_item.owned){
          owned++;
        }
      });
      if(this.template !== null){
        return owned >= parseInt(this.template.own_nav_count);
      }
    },
  }
}
</script>

<style scoped>
.web-navbar {
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
  border-radius: 4px;
  font-size: 16px;
  z-index: 100;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > a:after {
  content: "\f0da";
  border: none;
  font-family: 'FontAwesome';
  position: absolute;
  right: 16px;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.3);
}

.web-navbar .dropdown-item {
  border-bottom: none;
}

.web-navbar .dropdown-item i {
  margin: 0;
  color: inherit;
  font-size: 15px;
  text-align: inherit;
}

.navbar-light .navbar-nav .nav-link {
  color: #3C383E;
}

.web-navbar .navbar-nav .nav-link,
.web-navbar .navbar-nav a{
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
}

.web-navbar .navbar-nav .fa-plus {
  color: var(--primary) !important;
  margin-right: 8px;
}

.disabled-nav-link {
  color: #BABABA !important;
}

</style>
